import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
    const images = [
        // {
        //     original: "assets/img/slider/GearsDistrict.jpg",
        //     thumbnail: "assets/img/slider/GearsDistrict.jpg",
        // },
        // {
        //     original: "assets/img/slider/GearsDistrict2.jpg",
        //     thumbnail: "assets/img/slider/GearsDistrict2.jpg",
        // },

        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (1).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (1).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (2).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (2).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (3).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (3).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (4).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (4).jpg",
        },

        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (6).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (6).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (7).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (7).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (8).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (8).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (9).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (9).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (10).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (10).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (11).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (11).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (12).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (12).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (13).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (13).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (14).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (14).jpg",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (15).jpg",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (15).jpg",
        },


        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (1).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (1).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (10).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (10).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (11).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (11).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (12).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (12).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (13).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (13).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (14).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (14).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (15).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (15).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (16).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (16).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (17).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (17).png",
        },
        {
            original: "assets/img/images/GearsDistrict/GearsDistrict (18).png",
            thumbnail: "assets/img/images/GearsDistrict/GearsDistrict (18).png",
        },


    ];
    return (
        <div>
            <Fade ><ImageGallery infinite items={images} /></Fade>

            <section className="game-single-area pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-sm-6 mb-30">
                                        <div className='img-reveal'>
                                            <img className="img1 img-fluid" src="assets/img/images/gearsdistrict_slide1.png" alt="GearsDistrict SciFi Base 3D Asset Greebles" />
                                            <img className="img2 img-fluid" src="assets/img/images/gearsdistrict_slide2.png" alt="GearsDistrict SciFi Base 3D Asset Greebles" />
                                        </div>    </div>
                                    <div className="col-sm-6">
                                        <Fade >
                                            <p>
                                                Welcome to the Gears District – the heart of steampunk era.
                                                This collection of steampunk constructs captures the essence of a world powered by gears and steam. With
                                                copper enhanced forms and robust brass structures, you can build sprawling steampunk landscapes or
                                                mysterious environment settings. Perfect for any creator looking to infuse their work with the alluring
                                                character of steampunk design. Start building your mechanical world today!
                                            </p>
                                        </Fade>
                                        <Fade >
                                            <div className="centergt">
                                                <a
                                                    target="_blank" rel="noreferrer"
                                                    href="https://blendermarket.com/products/gears-district---steampunk-constructs-kitbash-greeble-assets"
                                                    className="sticky-footer-button btn btn-style-two"
                                                >
                                                    PURCHASE NOW
                                                </a>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SingleArea;
