import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
function WhyUs() {
  return (
    <section className="cta-area cta-bg">
      <div className="container">
        <div
          style={{ textAlign: "center", margin: "0 auto" }}
          className="row align-items-center section-title title-style-three white-title mb-30"
        >
          <h2 style={{ textAlign: "center", margin: "0 auto" }} className="">
            WHY <span>US?</span>
          </h2>
          <p className="mt-30 mb-20">
            Discover the Greeble Town difference with meticulously crafted,
            affordable, and versatile assets, PBR textures that tell a story,
            scatter-friendly design, and a passionate drive to produce only the
            best.
          </p>
        </div>

        <div className="row">
          <div style={{ width: "100%", padding: "50px" }} className="col-sm-6 my-auto order-0 order-lg-2">
            <div className='img-reveal mx-auto rounded'>
              <img className="img1 img-fluid wow fadeInRight rounded" data-wow-delay=".3s" alt="Greeble Town Lightweight Density" src="assets/img/images/Why Us 1a.jpg" />
              <img className="img2 img-fluid wow fadeInRight rounded" data-wow-delay=".3s" alt="Greeble Town Lightweight Density" src="assets/img/images/Why Us 1b.jpg" />


            </div>
          </div>
          <div className="col-sm-6 my-auto ">
            <div
              style={{ width: "100%", padding: "20px", }}
              className="just-gamers-list"
            ><i
              style={{
                fontSize: "20px",
                color: "#ff6400", display: "inline", marginRight: "20px"
              }}
              className="fas fa-check ml-20"
            ></i>
              <h3 style={{ fontWeight: "700", color: "#ff6400", display: "inline", }}>
                Lightweight Mesh Density
              </h3>
              <p className="mt-30 mb-20">
                A lightweight mesh density helps in a smoother real-time performance in 3D applications, ensuring optimal user experiences.
              </p>
            </div>
          </div>
        </div>


        <div className="row">
          <div style={{ width: "100%", padding: "50px" }} className="col-sm-6 my-auto">
            {/* <Image
              className="mx-auto"
              style={{ width: "100%" }}
              src="assets/img/images/Why Us 2.jpg"
              rounded
            /> */}

            <div className='img-reveal mx-auto rounded'>
              <img className="img1 img-fluid wow fadeInRight rounded" data-wow-delay=".3s" alt="Greeble Town PBR Textured" src="assets/img/images/Why Us 2a.jpg" />
              <img className="img2 img-fluid wow fadeInRight rounded" data-wow-delay=".3s" alt="Greeble Town PBR Textured" src="assets/img/images/Why Us 2b.jpg" />


            </div>
          </div>
          <div className="col-sm-6 my-auto">
            <div
              style={{ width: "100%", padding: "20px", }}
              className="just-gamers-list"
            ><i
              style={{
                fontSize: "20px",
                color: "#ff6400", display: "inline", marginRight: "20px"
              }}
              className="fas fa-check ml-20"
            ></i>
              <h3 style={{ fontWeight: "700", color: "#ff6400", display: "inline", }}>
                PBR Textured/UVs Unwrapped
              </h3>
              <p className="mt-30 mb-20">
                PBR textures enhance believability, making materials react to light in a real world manner, offering a touch of realism, and improving exports and cross software compatibility. We offer 1k,2k, and 4k resolutions. 1K and 2K resolutions are recommended for the intended use of scattering and also in massive scenes.
              </p>
            </div>
          </div>
        </div>


        <div className="row">
          <div style={{ width: "100%", padding: "50px" }} className="col-sm-6 my-auto order-0 order-lg-2">
            <Image
              className="mx-auto"
              style={{ width: "100%" }}
              src="assets/img/images/Why Us 3.gif"
              rounded
            />
          </div>
          <div className="col-sm-6 my-auto">
            <div
              style={{ width: "100%", padding: "20px", }}
              className="just-gamers-list"
            ><i
              style={{
                fontSize: "20px",
                color: "#ff6400", display: "inline", marginRight: "20px"
              }}
              className="fas fa-check ml-20"
            ></i>
              <h3 style={{ fontWeight: "700", color: "#ff6400", display: "inline", }}>
                Scatter Friendly
              </h3>
              <p className="mt-30 mb-20">
                We thought of building expansive environments when we created these assets. It will greatly help in the performance for scattering in massive 3D environments.Premade Scatterpacks available for the Free Biome-Reader or Geo-Scatter plugin.
              </p>
              <div style={{ width: "100%", padding: "25px" }} className="row">
                <div className="col-sm-6 my-auto">
                  <a className="geo-btn" href="https://www.geoscatter.com/download.html" target="_blank" rel="noopener noreferrer">
                    <img style={{width: "100%"}} src={"assets/img/images/biomereader.png"} alt="biomereader geoscatter" />
                  </a>
                </div>

                <div className="col-sm-6 my-auto">
                  <a className="geo-btn" href="https://www.geoscatter.com/download.html#geo-scatter" target="_blank" rel="noopener noreferrer">
                    <img style={{width: "100%"}} src={"assets/img/images/geoscatter.png"} alt="geoscatter" />
                  </a>

                </div>

                

              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div style={{ width: "100%", padding: "50px" }} className="col-sm-6 my-auto">
            <div className='img-reveal mx-auto'>
              <img className="img1 img-fluid wow fadeInRight rounded" data-wow-delay=".3s" alt="Greeble Town Extras" src="assets/img/images/Why Us 4a.png" />
              <img className="img2 img-fluid wow fadeInRight rounded" data-wow-delay=".3s" alt="Greeble Town Extras" src="assets/img/images/Why Us 4b.png" />


            </div>
          </div>
          <div className="col-sm-6 my-auto">
            <div
              style={{ width: "100%", padding: "20px", }}
              className="just-gamers-list"
            ><i
              style={{
                fontSize: "20px",
                color: "#ff6400", display: "inline", marginRight: "20px"
              }}
              className="fas fa-check ml-20"
            ></i>
              <h3 style={{ fontWeight: "700", color: "#ff6400", display: "inline", }}>
                Lots of Extras
              </h3>
              <p className="mt-30 mb-20">
                Explore more assets for scene creation, expanding your creative possibilities with a wider range of options and elements. We have added extra assets from smoke planes, holograms, rooftop assets, PBR tiling materials to light planes depending on the usage of each pack.
              </p>
            </div>
          </div>
        </div>



      </div>
    </section>
  );
}

export default WhyUs;
