import React, { useState } from "react";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Modal from "react-bootstrap/Modal";

import IndustrialSingleArea from "./IndustrialPack";
import MetropunkSingleArea from "./Metropunk";
import ModernCitiesSingleArea from "./ModernCities";
import SciFiGreeblesSingleArea from "./SciFiGreebles";
import MotherboardSingleArea from "./Motherboard";
import GridArmadaSingleArea from "./GridArmada";
import EmpireSandsSingleArea from "./EmpireSands";
import StarForgeSingleArea from "./StarForge";
import GearsDistrictSingleArea from "./GearsDistrict";
import DystopianSingleArea from "./Dystopian";

function Packs() {
  const [Industrial, setShowIndustrial] = useState(false);
  const handleCloseIndustrial = () => setShowIndustrial(false);
  const handleShowIndustrial = () => setShowIndustrial(true);

  const [Metropunk, setShowMetropunk] = useState(false);
  const handleCloseMetropunk = () => setShowMetropunk(false);
  const handleShowMetropunk = () => setShowMetropunk(true);

  const [ModernCities, setShowModernCities] = useState(false);
  const handleCloseModernCities = () => setShowModernCities(false);
  const handleShowModernCities = () => setShowModernCities(true);

  const [SciFiGreebles, setShowSciFiGreebles] = useState(false);
  const handleCloseSciFiGreebles = () => setShowSciFiGreebles(false);
  const handleShowSciFiGreebles = () => setShowSciFiGreebles(true);

  const [Motherboard, setShowMotherboard] = useState(false);
  const handleCloseMotherboard = () => setShowMotherboard(false);
  const handleShowMotherboard = () => setShowMotherboard(true);

  const [GridArmada, setShowGridArmada] = useState(false);
  const handleCloseGridArmada = () => setShowGridArmada(false);
  const handleShowGridArmada = () => setShowGridArmada(true);

  const [EmpireSands, setShowEmpireSands] = useState(false);
  const handleCloseEmpireSands = () => setShowEmpireSands(false);
  const handleShowEmpireSands = () => setShowEmpireSands(true);

  const [StarForge, setShowStarForge] = useState(false);
  const handleCloseStarForge = () => setShowStarForge(false);
  const handleShowStarForge = () => setShowStarForge(true);

  const [GearsDistrict, setShowGearsDistrict] = useState(false);
  const handleCloseGearsDistrict = () => setShowGearsDistrict(false);
  const handleShowGearsDistrict = () => setShowGearsDistrict(true);

  const [Dystopian, setShowDystopian] = useState(false);
  const handleCloseDystopian = () => setShowDystopian(false);
  const handleShowDystopian = () => setShowDystopian(true);

  // const [Industrial, setShowIndustrial] = useState(false);
  // const handleCloseIndustrial = () => setShowIndustrial(false);
  // const handleShowIndustrial = () => setShowIndustrial(true);

  return (
    <section
      id="packs"
      className="featured-game-area position-relative  pt-50 pb-90"
    >
      <div className="featured-game-bg" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three text-center mb-25">
              <Fade big cascade>
                <img className="mb-10 mt-0 col-sm-12 home-logo" style={{ position: "relative", width: "300px" }} src="assets/img/images/Greeble Packs.png" alt="Greeble Town" />
                <p>
                  Discover our <span style={{ fontWeight: "Bold", fontFamily: "Poppins" }}>high-quality and versatile</span> assets.
                </p></Fade>
            </div>
          </div>
        </div>
        <div className="row featured-active">


          {/* PACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowIndustrial}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/Industrial_Thumbnail.jpg" alt="" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowIndustrial}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowIndustrial}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  GRUNGE <span>FACTORY</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowIndustrial}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>

            <Modal
              show={Industrial}
              onHide={handleCloseIndustrial}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseIndustrial}
                />
                <div className="section-title title-style-three mb-0 pb-0">

                  <img style={{ width: "300px" }} src="assets/img/images/IndustrialHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-industrial">
                <IndustrialSingleArea></IndustrialSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>
          {/* PACKS */}

          {/*METROPUNK PACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowMetropunk}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/Metropunk_Thumbnail.jpg" alt="" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowMetropunk}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowMetropunk}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  METRO<span>PUNK</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowMetropunk}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>
            <Modal
              show={Metropunk}
              onHide={handleCloseMetropunk}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseMetropunk}
                />
                <div className="section-title title-style-three mb-0 pb-0">
                  <img style={{ width: "300px" }} src="assets/img/images/MetroPunkHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-metropunk">
                <MetropunkSingleArea></MetropunkSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>

          {/*METROPUNK PACKS */}

          {/*ModernCities PACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowModernCities}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/ModernCities_Thumbnail.jpg" alt="" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowModernCities}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowModernCities}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  SKYLINE <span>UKIYO</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowModernCities}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>
            <Modal
              show={ModernCities}
              onHide={handleCloseModernCities}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseModernCities}
                />
                <div className="section-title title-style-three mb-0 pb-0">
                  <img style={{ width: "300px" }} src="assets/img/images/ModernCitiesHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-moderncities">
                <ModernCitiesSingleArea></ModernCitiesSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>

          {/*ModernCities PACKS */}

          {/*Steampunk PACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowGearsDistrict}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/Steampunk_Thumbnail.jpg" alt="Steampunk 3D Asset Kitbash Kits" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowGearsDistrict}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowGearsDistrict}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  GEARS <span>DISTRICT</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowGearsDistrict}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>
            <Modal
              show={GearsDistrict}
              onHide={handleCloseGearsDistrict}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseGearsDistrict}
                />
                <div className="section-title title-style-three mb-0 pb-0">
                  <img style={{ width: "300px" }} src="assets/img/images/SteampunkHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-grid">
                <GearsDistrictSingleArea></GearsDistrictSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>

          {/*Steampunk PACKS */}

          {/*Grid Armada PACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowGridArmada}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/Grid_Thumbnail.jpg" alt="Military Base Pack" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowGridArmada}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowGridArmada}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  GRID <span>ARMADA</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowGridArmada}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>
            <Modal
              show={GridArmada}
              onHide={handleCloseGridArmada}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseGridArmada}
                />
                <div className="section-title title-style-three mb-0 pb-0">
                  <img style={{ width: "300px" }} src="assets/img/images/GridArmadaHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-grid">
                <GridArmadaSingleArea></GridArmadaSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>

          {/*Grid Armada PACKS */}

          {/*StarForgePACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowStarForge}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/StarForge_Thumbnail.jpg" alt="Space Station 3D Assets" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowStarForge}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowStarForge}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  STARFORGE<span>NEXUS</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowStarForge}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>
            <Modal
              show={StarForge}
              onHide={handleCloseStarForge}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseStarForge}
                />
                <div className="section-title title-style-three mb-0 pb-0">
                  <img style={{ width: "300px" }} src="assets/img/images/StarForgeHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-grid">
                <StarForgeSingleArea></StarForgeSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>

          {/*Starforge PACKS */}

          {/*Empire Sands PACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowEmpireSands}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/EmpireSands_Thumbnail.jpg" alt="Desert Dune 3D Asset Kitbash Kits" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowEmpireSands}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowEmpireSands}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  EMPIRE <span>SANDS</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowEmpireSands}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>
            <Modal
              show={EmpireSands}
              onHide={handleCloseEmpireSands}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseEmpireSands}
                />
                <div className="section-title title-style-three mb-0 pb-0">
                  <img style={{ width: "300px" }} src="assets/img/images/EmpireSandsHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-grid">
                <EmpireSandsSingleArea></EmpireSandsSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>

          {/*Empire Sands PACKS */}

          



          {/*SciFiGreebles PACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowSciFiGreebles}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/SciFi_Thumbnail.jpg" alt="" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowSciFiGreebles}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowSciFiGreebles}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  FREE <span>FUTURE SCRATCH</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowSciFiGreebles}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>
            <Modal
              show={SciFiGreebles}
              onHide={handleCloseSciFiGreebles}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseSciFiGreebles}
                />
                <div className="section-title title-style-three mb-0 pb-0">
                  <img style={{ width: "300px" }} src="assets/img/images/SciFiHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-scifi">
                <SciFiGreeblesSingleArea></SciFiGreeblesSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>

          {/*SciFiGreebles PACKS */}

          {/*Motherboard PACKS */}
          <Fade big><div className="col-lg-4 col-sm-6 grid-item">
            <div
              className="featured-game-item mb-30"
              onClick={handleShowMotherboard}
            >
              <div className="featured-game-thumb">
                <img src="assets/img/images/MoBo_Thumbnail.jpg" alt="" />
              </div>

              <div
                className="featured-game-content featured-game-overlay-content"
                onClick={handleShowMotherboard}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-icon"
                  onClick={handleShowMotherboard}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="assets/img/icon/featured_game_icon.png"
                    alt=""
                  />
                </div>
                <h4 style={{ cursor: "pointer" }}>
                  FREE <span>BINARY FRAME</span>
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  className="featured-game-meta"
                  onClick={handleShowMotherboard}
                >
                  <i className="fas fa-hand-point-right" />
                  <span>READ MORE</span>
                </div>
              </div>
            </div>
          </div></Fade>
          <Zoom>
            <Modal
              show={Motherboard}
              onHide={handleCloseMotherboard}
              className=".modal-dialog-centered"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header className="modal-header--sticky">
                <img
                  className="sticky-close"
                  src="assets/img/icon/close.svg"
                  alt=""
                  onClick={handleCloseMotherboard}
                />
                <div className="section-title title-style-three mb-0 pb-0">
                  <img style={{ width: "300px" }} src="assets/img/images/MotherboardHeader.png" alt="" />
                </div>
              </Modal.Header>
              <Modal.Body className="modal-body-mobo">
                <MotherboardSingleArea></MotherboardSingleArea>
              </Modal.Body>
            </Modal>
          </Zoom>

          {/*Motherboard PACKS */}





          {/* <div className="col-lg-4 col-sm-6 grid-item">
            <Link to="/">
              <div className="featured-game-item mb-30">
                <div className="featured-game-thumb">
                  <img
                    src="assets/img/images/coming-soon_Thumbnail.jpg"
                    alt=""
                  />
                </div>

                <div className="coming-soon featured-game-content featured-game-overlay-content">
                  <div className="featured-game-icon">
                    <img src="assets/img/icon/featured_game_icon.png" alt="" />
                  </div>
                  <h4>
                    <Link to="/">
                      COMING <span>SOON</span>
                    </Link>
                  </h4>

                </div>
              </div>
            </Link>
          </div> */}

        </div>

      </div>
    </section>
  );
}

export default Packs;
